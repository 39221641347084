import axios from "axios";
import {
    ProductType
} from "../utils/generalTypes";

const instance = axios.create({
    baseURL: "https://loftrt.ru/api/",
})

export const CatalogAPI = {

    async getMaxPriceForProducts(){
        return await instance.get<number>(
            `items_max_price/`, {params: {}}
        ).then(response => response)
    },

    async getAllProducts(counter: string, material: string, type: string, price_range: string, sort: string, search_query: string){
        // console.log(counter, material, type, price_range, sort, search_query)
        return await instance.get<Array<ProductType>>(
            `items_list/`, {params: {counter, material, type, price_range, sort, search_query}}
        ).then(response => response)
    },
}

export const ProductAPI = {
    async getProductPageById(itemId: string | undefined){
        // console.log(itemId)
        return await instance.get<ProductType>(
            `item_self/`, {params: {itemId}}
        ).then(response => response)
    },
}

export const AuditAPI = {
    async getPostAudit(name: string, number: string, comment: string, items: string, cost: string){
        console.log(name, number, comment, items, cost, "api")
        return await instance.post(
            'order_make/', null, {params: {name, number, comment, items, cost}}
        ).then(response => response)
    },
}