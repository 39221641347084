import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// import App from './App';
import "./App.css";
import "./main.css";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/redux-store";
import { Provider } from "react-redux";
import Preloader from './assets/preloaders/Preloader';
import { withSuspense } from './hoc/withSuspense';

const AppPage = React.lazy( () => import ('./App') )
const App = withSuspense(AppPage)

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <Suspense fallback={<div><Preloader /></div>}>
                    <App />
                </Suspense>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);