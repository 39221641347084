import React, { Dispatch } from "react";
import {
    ProductType, 
} from "../utils/generalTypes";
import {ThunkAction} from "redux-thunk";
import {AppStateType, InferActionsTypes, useTypedDispatch} from "./redux-store";
import {AuditAPI, CatalogAPI, ProductAPI} from "../api/api";
import {AxiosResponse} from 'axios';
import prodImg from "../assets/images/prodImg.jpg"

type InitialStateType = {
    maxPrice: number
    products: Array<ProductType>
    product: ProductType
    isShowPreloader: boolean
    productTotalCount: number
    basketItems: Array<ProductType>
    pageCounter: number
    productType: Array<string>
    price_range0: number
    price_range1: number
    priceSort: string
    search_query: string | null
    auditMessage: string
}

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsType>
let initialState: InitialStateType = {
    maxPrice: 0,
    products: [
        // {
        //     "id": 1,
        //     "name": "Стул “Триножный”",
        //     "material": "Материал: сосна",
        //     "size": "18*200",
        //     "type": "",
        //     "for_what": "",
        //     "description": "Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.",
        //     "photo_links": [
        //         prodImg,
        //         prodImg,
        //         prodImg,
        //         prodImg
        //     ],
        //     "article": "text",
        //     "price": 1999,
        //     "unit": "шт",
        //     "added_at": "2023-10-08 19:04:52"
        // },
        // {
        //     "id": 2,
        //     "name": "Стул “Триножный”",
        //     "material": "Материал: сосна",
        //     "size": "18*200",
        //     "type": "",
        //     "for_what": "",
        //     "description": "Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.",
        //     "photo_links": [
        //         prodImg,
        //         prodImg,
        //         prodImg,
        //         prodImg
        //     ],
        //     "article": "text1",
        //     "price": 1999,
        //     "unit": "шт",
        //     "added_at": "2023-10-08 19:04:52"
        // }
    ],
    product: {},
    isShowPreloader: false,
    productTotalCount: 2,
    basketItems: [
        // {
        //     "id": 1,
        //     "name": "Стул “Триножный”",
        //     "material": "Материал: сосна",
        //     "size": "18*200",
        //     "type": "",
        //     "for_what": "",
        //     "description": "Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.",
        //     "photo_links": [
        //         prodImg,
        //         prodImg,
        //         prodImg,
        //         prodImg
        //     ],
        //     "preview": prodImg,
        //     "article": "text",
        //     "price": 1999,
        //     "unit": "шт",
        //     "added_at": "1"
        // },
        // {
        //     "id": 1,
        //     "name": "Стул “Триножный”",
        //     "material": "Материал: сосна",
        //     "size": "18*200",
        //     "type": "",
        //     "for_what": "",
        //     "description": "Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.Элегантный стул на трехх ножках, идеально впишется в любой интерьер. Приобретите уже сейчас.",
        //     "photo_links": [
        //         prodImg,
        //         prodImg,
        //         prodImg,
        //         prodImg
        //     ],
        //     "preview": prodImg,
        //     "article": "text",
        //     "price": 1999,
        //     "unit": "шт",
        //     "added_at": "1"
        // },
    ],
    pageCounter: 1,
    productType: [],
    price_range0: 0,
    price_range1: 176000,
    priceSort: "default",
    search_query: null,
    auditMessage: ""
}

const catalogReducer = (
    state = initialState,
    action: ActionsType
): InitialStateType => {
    switch (action.type) {
        case "SET-MAX-PRICE":
            return {
                ...state,
                maxPrice: action.maxPrice,
            }
        case "SET-PRODUCTS":
            return {
                ...state,
                products: action.products
            }
        case "SET-PRODUCT-BY-ID":
            return {
                ...state,
                product: action.product
            }
        case "SHOW-PRELOADER":
            return {
                ...state, isShowPreloader: action.setShow
            }
        case "SET-TOTAL-COUNT":
            return {
                ...state,
                productTotalCount: action.productTotalCount,
            }
        case "SET-BASKET-ITEM":
            return {
                ...state, 
                basketItems: [...state.basketItems, action.basketItem]
            }
        case "SET-REMOVE-BASKET-ITEM":
            return{
                ...state,
                basketItems: [...action.basketItem]
            }
        case "SET-PAGE-COUNTER":
            return{
                ...state,
                pageCounter: action.pageCounter
            }
        case "SET-PRODUCT-TYPE":
            return{
                ...state,
                productType: action.productType
            }
        case "SET-PRICE-RANGE-0":
            return{
                ...state,
                price_range0: action.price_range0
            }
        case "SET-PRICE-RANGE-1":
            return{
                ...state,
                price_range1: action.price_range1
            }
        case "SET-PRICE-SORT":
            return{
                ...state,
                priceSort: action.priceSort
            }
        case "SET-SEARCH-QUERY":
            return{
                ...state,
                search_query: action.search_query
            }
        case "SET-AUDIT-RESP":
            return{
                ...state,
                auditMessage: action.message
            }
        default:
            return state;
    }
}

type ActionsType = InferActionsTypes<typeof catalogActions>

export const catalogActions = {
    onSetMaxPrice: (maxPrice: number) => ({type: "SET-MAX-PRICE", maxPrice} as const),
    onSetProducts: (products: Array<ProductType>) => ({type: "SET-PRODUCTS", products} as const),
    onSetProductById: (product: ProductType) => ({type: "SET-PRODUCT-BY-ID", product} as const),
    setShowPreloader: (setShow: boolean) => ({type: "SHOW-PRELOADER", setShow} as const),
    onSetProductTotalCount: (productTotalCount: number) => ({type: "SET-TOTAL-COUNT", productTotalCount} as const),
    onSetBasketItem: (basketItem: ProductType) => ({type: "SET-BASKET-ITEM", basketItem} as const),
    onSetRemoveItem: (basketItem: ProductType) => ({type: "SET-REMOVE-BASKET-ITEM", basketItem} as const),
    onSetPageCounter: (pageCounter: number) => ({type: "SET-PAGE-COUNTER", pageCounter} as const),
    onSetProductType: (productType: Array<string>) => ({type: "SET-PRODUCT-TYPE", productType} as const),
    onSetPriceRange0: (price_range0: number) => ({type: "SET-PRICE-RANGE-0", price_range0} as const),
    onSetPriceRange1: (price_range1: number) => ({type: "SET-PRICE-RANGE-1", price_range1} as const),
    onSetPriceSort: (priceSort: string) => ({type: "SET-PRICE-SORT", priceSort} as const),
    onSetSearchQuery: (search_query: string | null) => ({type: "SET-SEARCH-QUERY", search_query} as const),
    onSetAuditResp: (message: string) => ({type: "SET-AUDIT-RESP", message} as const),
}

export const getMaxPrice = (): ThunkType => {
    return async (dispatch) => {
        CatalogAPI.getMaxPriceForProducts()
            .then((response: AxiosResponse<any>) => {
                // console.log(response.data)
                dispatch(catalogActions.onSetMaxPrice(response.data.max_price));
            }
        )
    }
}

export const getProducts = (
        counter: string, material: string, type: string, price_range: string, sort: string, search_query: string
    ): ThunkType => {
    return async (dispatch) => {
        dispatch(catalogActions.setShowPreloader(true))
        // console.log(counter, material, type, price_range, sort, search_query)
        CatalogAPI.getAllProducts(counter, material, type, price_range, sort, search_query)
            .then((response: AxiosResponse<Array<ProductType>> | any) => {
                // console.log(counter, material, type, price_range, sort, search_query, response.data.data)
                dispatch(catalogActions.onSetProductTotalCount(response.data.amount))
                dispatch(catalogActions.onSetProducts(response.data.data))
                dispatch(catalogActions.setShowPreloader(false))
            }
        )
    }
}

export const getProductById = (itemId: string | undefined): ThunkType => {
return async (dispatch) => {
    dispatch(catalogActions.setShowPreloader(true))
    // console.log(itemId)
    ProductAPI.getProductPageById(itemId)
        .then((response: AxiosResponse<Array<ProductType>>) => {
            // console.log(response.data)
            dispatch(catalogActions.onSetProductById(response.data))
            dispatch(catalogActions.setShowPreloader(false))
        }
    )
}
}

export const postAudit = (name: string, number: string, comment: string, items: string, cost: string): ThunkType => {
    // console.log(String(name), String(number), String(comment), String(items))
    return async (dispatch) => {
    // console.log(String(name), String(number), String(comment), items)
        AuditAPI.getPostAudit(
            String(name), String(number), String(comment), String(items), cost
        ).then((response: AxiosResponse<any>) => {
            // console.log(response, "A")
            dispatch(catalogActions.onSetAuditResp(response.data.message));
        })
}
}

// export const setBasketItem = (basketItem: ProductType, counter: number) => {
//     return async (dispatch: Dispatch<ActionsType>) => {
//         dispatch(catalogActions.onSetBasketItem(basketItem));
//     };
// }

// export const setBasketItem = (basketItem: ProductType, counter: number): ThunkType => {
//     console.log(basketItem)
//     return async (dispatch) => {
//         ProductAPI.getProductPageById(basketItem)
//             .then((response: AxiosResponse<Array<ProductType>>) => {
//                 // console.log(response.data)
//                 dispatch(catalogActions.onSetBasketItem(response.data))
//             }
//         )
//     }
// }

// export const setBasketItem = (product: number): ThunkType => {
//     return async (dispatch) => {
//         console.log(itemId, "2")
//         ProductAPI.getProductPageById(String(itemId))
//             .then((response: AxiosResponse<Array<ProductType>> | any) => {
//                 // console.log(response.data)
//                 dispatch(catalogActions.onSetBasketItem(response.data))
//         })
//     }
// }

// export const addProduct = (product: ProductType, counter: number) => {
//     const existingProduct = initialState.basketItems.find(item => item.id === product.id);
    
//     if (existingProduct) {
//         existingProduct.added_at = String(Number(existingProduct.added_at) + 1);
//         dispatch(catalogActions.onSetBasketItem([...initialState.basketItems]));
//     } else {
//         product.added_at = String(counter);
//         dispatch(catalogActions.onSetBasketItem([...initialState.basketItems, product]));
//     }
// };

// export const removeItemById = (idToRemove: number): Array<ProductType> => {
//     const updatedArray = initialState.basketItems.filter((item) => item.id !== idToRemove);
//     dispatch(catalogActions.onSetBasketItem(updatedArray));
// };


export default catalogReducer;