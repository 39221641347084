import React from "react";
import classes from "./Preloader.module.css";
import NewPreloader from "./NewPreloader.gif"

const Preloader = () =>{
    return(
        // <div className={classes.hypnotic}></div>
        <div className={classes.newPreloader}><img src={NewPreloader} alt="loading..." /></div>
    );
};

export default Preloader;