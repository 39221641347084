import React from "react";
import Preloader from "../assets/preloaders/Preloader";


export const withSuspense = (Component) => (props) => {
	return (
		<React.Suspense fallback={<Preloader/>}>
			<Component {...props} />
		</React.Suspense>
	)
}